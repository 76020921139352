import EventEmitter from 'eventemitter3';

export default class Emitter {
  static polyfill() {
    window.FND = window.FND || {};

    if (!window.FND.eventEmitter) {
      window.FND.eventEmitter = new EventEmitter();
    }

    return window.FND.eventEmitter;
  }
}
