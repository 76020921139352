export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item));
}

export function detectShop() {
  
  // Grab Shopify Shop ID
  const shop = JSON.parse(document.getElementById('shop-id').innerText).shop_id;
  console.log("Shop:", shop);

// Determine Staging or Production
if (shop === 55707992199 ) {
  console.log("We are staging");
    return  "https://us-central1-cag-sandbox-a7de1700.cloudfunctions.net/"
}
if (shop === 66419982568 ) {
  console.log("We are prod");
   return  "https://us-central1-cag-prod-bd323715.cloudfunctions.net/"
}
}

export function detectStripeMode() {
  
  // Grab Shopify Shop ID
  const shop = JSON.parse(document.getElementById('shop-id').innerText).shop_id;
  console.log("Shop:", shop);

// Determine Staging or Production
if (shop === 55707992199 ) {
  console.log("We are Stripe staging");
    return  "pk_test_51MQKx1ByAFUCFEYaLadCHnp2a79Nbmo5h4jp4bSvhaWRtXMvxySp6EGdjd0MPORVPoNrqho8szNa3fskuVRT10ut00oJOK6Yet"
}
if (shop === 66419982568 ) {
  console.log("We are Stripe prod");
   return  "pk_live_51MQKx1ByAFUCFEYa9BF9IedomMuluJKrybg84Shio04swiEVtXaNlR9QIhSaU0LrT62tDmddJjksy7FC8bcsPgU1000Exj8LXS"
}

}
export function showApplicatorStatus() {
  
  // Quick Fix move & show the applicator license div
  $("#fe-permit-details").append(document.querySelector("#fe_applicator_license_status"));
  $("#fe_applicator_license_status").show();

}

export function showTaxExemptStatus() {
  
  // Quick Fix move & show the applicator license div
  $("#fe_tax_heading").append(document.querySelector("#fe_tax_exemption_status"));
  $("#fe_tax_exemption_status").show();

}
