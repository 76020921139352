import EventEmitter from './helpers/EventEmitter';
import {detectShop, detectStripeMode, getWithExpiry, setWithExpiry, showApplicatorStatus, showTaxExemptStatus} from './helpers/localStorage'

// Add Event Emitter Functionality
EventEmitter.polyfill();

const FE = (window.FE = window.FE || {});
FE.BUILDS = FE.BUILDS || {};
FE.BUILDS.Theme = FE.BUILDS.Theme || {};
FE.BUILDS.Theme.getWithExpiry = getWithExpiry;
FE.BUILDS.Theme.setWithExpiry = setWithExpiry;
FE.BUILDS.Theme.baseURL = detectShop;
FE.BUILDS.Theme.detectStripeMode = detectStripeMode;
FE.BUILDS.Theme.showApplicatorStatus = showApplicatorStatus;
FE.BUILDS.Theme.showTaxExemptionStatus = showTaxExemptStatus;

// FE.BUILDS.Theme.init = ThemePresenter;

/**
 * Uncomment the Below and you can use in snippets,
 * name snippet FE-theme-init.liquid
 *
 */

// {% comment %}
// This snippet has all BMS files the theme requires. Also it should to be included only once per theme.
// {% endcomment %}
//
//
//
// {{ 'FE.theme.bundle.css' | asset_url | stylesheet_tag }}
// <script src="{{ 'FE.theme.bundle.js' | asset_url }}" defer></script>
//
//
// {% comment %} Put your Liquid Theme settings below! {% endcomment %}
// <script id="theme-settings-json" type="application/json">
//     {
//     }
// </script>
//
// <script id="theme-object-json" type="application/json">
//     {{ theme | json }}
// </script>
//
// <script>
//   document.addEventListener("DOMContentLoaded", function() {
//     var theme = JSON.parse(document.querySelector('#theme-object-json').innerHTML);
//     var settings = JSON.parse(document.querySelector('#theme-settings-json').innerHTML);
//     new FE.BUILDS.Theme(settings, theme);
//   });
// </script>